import React, { useEffect, useState, useRef } from 'react';
import { RequestTypes } from '../../../models';

type UrlEditorProps = {
  url: string;
  /**
   * setUrl Function Type
   */
  //setUrl: (url: string) => void;
  reqMethod: RequestTypes;
  isURLReadOnly: boolean;
  /**
   * set url request data
   */
  setUrl: (url: string) => void;
  /**
   * setReqMethod Function Type
   */
  setReqMethod: (method: RequestTypes) => void;
  /**
   * onInputSend Function Type
   */
  onInputSend: (toOurBackend: boolean) => Promise<void>;
  /**
   * setQueryParams Function Type
   */
  // setQueryParams: (queryParams: Pair<string[]>) => void;
};

/**
 *  UrlEditor Component
 */
export default function UrlEditor({
  url,
  reqMethod,
  isURLReadOnly = true,
  setReqMethod,
  onInputSend,
  setUrl,
}: // setQueryParams,
UrlEditorProps) {
  const [updatedUrl, setUpdatedUrl] = useState(url);
  const [openSendDropDown, setOpenSendDropDown] = useState(false);
  const inputUrlRef = useRef(null);

  /**
   * handle change url for local state variable
   */
  const handleUrlChange = (val: string) => {
    setUpdatedUrl(val);
    setUrl(val);
  };
  /**
   *  Filter Url
   */
  // const splitInput = (input: string) => {
  //   const parts = input.split(/(\{\{[^}]+\}\})/);
  //   return parts.map((part, index) => {
  //     if (part.match(/^\{\{[^}]+\}\}$/)) {
  //       // This part contains {{someVariable}}
  //       return (
  //         <span
  //           key={index}
  //           style={{
  //             color: 'brown',
  //             background: 'rgb(250, 128, 114, 0.2)',
  //           }}
  //         >
  //           {part}
  //         </span>
  //       );
  //     } else {
  //       // This part does not contain {{someVariable}}
  //       return <span key={index}>{part}</span>;
  //     }
  //   });
  // };

  useEffect(() => {
    if (document.activeElement !== inputUrlRef.current && url !== updatedUrl)
      setUpdatedUrl(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return (
    <form className="flex">
      <select
        className="px-4 py-2 border rounded-md border-gray-300 hover:border-blue-500 focus:outline-none bg-gray-100"
        value={reqMethod}
        onChange={(e) => setReqMethod(e.target.value as RequestTypes)}
      >
        {Object.keys(RequestTypes)
          .filter((type) => isNaN(Number(type)))
          .map((method) => (
            <option key={method} value={method}>
              {method}
            </option>
          ))}
      </select>
      <div className="ml-3 w-full">
        <input
          className="w-full p-2 border rounded-md border-gray-300 hover:border-blue-500 focus:outline-none"
          value={updatedUrl}
          readOnly={isURLReadOnly}
          disabled={isURLReadOnly}
          onChange={(e) => handleUrlChange(e.target.value)}
          ref={inputUrlRef}
        />
      </div>
      <div className="relative inline-block text-left">
        <div>
          <button
            onClick={() => setOpenSendDropDown(!openSendDropDown)}
            type="button"
            className="ml-3 px-6 py-2 rounded-md font-semibold text-white bg-blue-500 hover:bg-orange-600"
            id="menu-button"
            aria-expanded="true"
            aria-haspopup="true"
          >
            Send
          </button>
        </div>
        {openSendDropDown && (
          <div
            className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex={-1}
          >
            <div
              className="py-1 hover:bg-orange-600 hover:cursor-pointer hover:text-white hover:rounded-t-md"
              role="none"
            >
              <button
                onClick={() => onInputSend(true)}
                className="block px-4 py-2 text-sm"
                type="button"
                id="menu-item-0"
              >
                Send (Server)
              </button>
            </div>
            <div
              className="py-1 hover:bg-orange-600 hover:cursor-pointer hover:text-white hover:rounded-b-md"
              role="none"
            >
              <button
                onClick={() => onInputSend(false)}
                className="block px-4 py-2 text-sm"
                type="button"
                id="menu-item-2"
              >
                Send (Local)
              </button>
            </div>
          </div>
        )}
      </div>
      {/* <button
        className="whitespace-nowrap ml-3 px-6 py-2 rounded-md font-semibold text-white bg-blue-500 hover:bg-orange-600"
        type="button"
        onClick={() => onInputSend(true)}
      >
        Send To Our Server
      </button>
      <button
        className="ml-3 px-6 py-2 rounded-md font-semibold text-white bg-blue-500 hover:bg-orange-600"
        type="button"
        onClick={() => onInputSend(false)}
      >
        Send
      </button> */}
    </form>
  );
}
