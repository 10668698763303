import React, { FC, useEffect, useState } from 'react';
import './Expandabletable.css';

type ExpandableTableType = {
  data: any[];
  columns: any[];
  rowClickHandler?: Function;
  viewName?: string;
};

/**
 *
 */
const ExpandableTable: FC<ExpandableTableType> = ({
  data,
  rowClickHandler,
  columns,
  viewName,
}) => {
  const [dataCopy, setDataCopy] = useState([...data]);

  useEffect(() => {
    setDataCopy([...data]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewName]);

  /**
   * Handle RawURI expansion method
   */
  const handleRawURIExpansion =
    (rawUri: string, rowIndex: number) =>
    (event: React.MouseEvent<HTMLElement>) => {
      let updatedDataCopy = [...dataCopy];
      let rowElement = updatedDataCopy[rowIndex];
      rowElement = { ...rowElement, isExpanded: !rowElement.isExpanded };
      updatedDataCopy.splice(rowIndex, 1, rowElement);

      const { subRows } = updatedDataCopy[rowIndex];
      if (
        rowElement.isExpanded &&
        Array.isArray(subRows) &&
        subRows.length > 1
      ) {
        const copySubRows = [...subRows];
        for (let i = 0; i < subRows.length - 1; i = i + 1) {
          copySubRows.shift();
          // will create new tr element for each expand just next to current one
          updatedDataCopy.splice(rowIndex + 1, 0, {
            ...updatedDataCopy[rowIndex],
            rawUri: '',
            duplicateRawUri: rawUri,
            subRows: copySubRows,
          });
        }
      } else {
        updatedDataCopy = updatedDataCopy.filter(
          (el) => el.duplicateRawUri !== rawUri
        );
      }
      setDataCopy(updatedDataCopy);
    };
  /**
   * Handle Flow Expansion method
   */
  const handleFlowExpansion =
    (flowName: string, rawUri: string, rowIndex: number) => () => {
      let updatedDataCopy = [...dataCopy];
      let rowElement = { ...updatedDataCopy[rowIndex] };
      if (rowElement.subRows.length > 0) {
        let flowElement = rowElement.subRows.filter(
          (flow: any) => flow.flowName === flowName
        )[0];
        let flowIndex = rowElement.subRows.findIndex(
          (flow: any) => flow.flowName === flowName
        );
        flowElement = { ...flowElement, isExpanded: !flowElement.isExpanded };
        rowElement.subRows.splice(flowIndex, 1, flowElement);
        updatedDataCopy.splice(rowIndex, 1, rowElement);

        const subRows = [...flowElement.subRows];
        const rowSubRows = [...rowElement.subRows];
        if (
          flowElement.isExpanded &&
          Array.isArray(subRows) &&
          subRows.length > 1
        ) {
          const copySubRows = [...subRows];
          for (let i = 0; i < subRows.length - 1; i = i + 1) {
            copySubRows.shift();
            rowSubRows.splice(flowIndex, 0, {
              ...flowElement,
              subRows: [...copySubRows],
            });
            // will create new tr element for each expand just next to current one
            updatedDataCopy.splice(rowIndex + 1, 0, {
              ...updatedDataCopy[rowIndex],
              rawUri: '',
              duplicateRawUri: rawUri,
              flowName: '',
              duplicateFlowName: flowName,
              subRows: [...rowSubRows],
            });
          }
        } else {
          if (Array.isArray(subRows) && subRows.length > 1)
            updatedDataCopy = updatedDataCopy.filter(
              (el) =>
                !(
                  el.duplicateFlowName === flowName &&
                  el.duplicateRawUri === rawUri
                )
            );
        }
        setDataCopy(updatedDataCopy);
      }
    };

  /**
   * Handle Node expansion method
   */
  const handleNodeExpansion =
    (nodeId: string, flowName: string, rawUri: string, rowIndex: number) =>
    () => {
      let updatedDataCopy = [...dataCopy];
      let rowElement = updatedDataCopy[rowIndex];
      if (rowElement.subRows.length > 0) {
        let flowElement = rowElement.subRows.filter(
          (flow: any) => flow.flowName === flowName
        )[0];
        let flowIndex = rowElement.subRows.findIndex(
          (flow: any) => flow.flowName === flowName
        );
        if (flowElement.subRows.length > 0) {
          let nodeElement = flowElement.subRows.filter(
            (node: any) => node.nodeId === nodeId
          )[0];
          let nodeIndex = flowElement.subRows.findIndex(
            (node: any) => node.nodeId === nodeId
          );
          nodeElement = {
            ...nodeElement,
            isExpanded: nodeElement.isExpanded ? false : true,
          };
          flowElement.subRows.splice(nodeIndex, 1, nodeElement);
          rowElement.subRows.splice(flowIndex, 1, flowElement);

          const { subRows } = nodeElement;
          const flowSubRows = [...flowElement.subRows];
          const rowSubRows = [...rowElement.subRows];

          if (
            nodeElement.isExpanded &&
            Array.isArray(subRows) &&
            subRows.length > 1
          ) {
            const copySubRows = [...subRows];
            for (let i = 0; i < subRows.length - 1; i = i + 1) {
              copySubRows.shift();
              flowSubRows.splice(nodeIndex, 0, {
                ...nodeElement,
                subRows: [...copySubRows],
              });
              rowSubRows.splice(flowIndex, 0, {
                ...flowElement,
                subRows: flowSubRows,
              });
              // will create new tr element for each expand just next to current one
              updatedDataCopy.splice(rowIndex + 1, 0, {
                ...updatedDataCopy[rowIndex],
                rawUri: '',
                duplicateRawUri: rawUri,
                flowName: '',
                duplicateFlowName: flowName,
                nodeId: '',
                duplicateNodeId: nodeId,
                subRows: [...rowSubRows],
              });
            }
          } else {
            if (Array.isArray(subRows) && subRows.length > 1)
              updatedDataCopy = updatedDataCopy.filter(
                (el) =>
                  !(
                    el.duplicateNodeId === nodeId &&
                    el.duplicateFlowName === flowName &&
                    el.duplicateRawUri === rawUri
                  )
              );
          }
          setDataCopy(updatedDataCopy);
        }
      }
    };

  return (
    <div className="overflow-auto max-h-screen">
      <div
        className="inline-block min-w-full align-middle"
        style={{ maxWidth: '78vw' }}
      >
        <table className="min-w-full max-h-full border-spacing-0">
          <thead className="sticky top-0 ">
            <tr>
              {columns.map((col) => {
                const { header, columns: subColumns } = col;
                let colSpan = 1;
                if (subColumns?.length > 1) colSpan = subColumns.length;
                return (
                  <th
                    scope="col"
                    colSpan={colSpan}
                    className="border-b border-gray-300 whitespace-nowrap bg-white/75 text-left capitalize font-semibold text-gray-900 backdrop-blur backdrop-filter py-3.5 px-3 "
                  >
                    {colSpan > 1 && header}
                  </th>
                );
              })}
            </tr>
            <tr>
              {columns.map((col) => {
                const { header, columns: subColumns } = col;
                if (!subColumns) {
                  return (
                    <th
                      scope="col"
                      colSpan={1}
                      className="border-b border-gray-300 whitespace-nowrap bg-white/75 text-left capitalize font-semibold text-gray-900 backdrop-blur backdrop-filter py-3.5 px-3"
                    >
                      {header}
                    </th>
                  );
                } else {
                  return subColumns.map((subCol: any) => {
                    return (
                      <th
                        scope="col"
                        colSpan={1}
                        className="border-b border-gray-300 whitespace-nowrap bg-white/75 text-left capitalize font-semibold text-gray-900 backdrop-blur backdrop-filter py-3.5 px-3 "
                      >
                        {subCol.header}
                      </th>
                    );
                  });
                }
              })}
            </tr>
          </thead>

          <tbody>
            {dataCopy.map((item, uriIndex) => (
              <React.Fragment key={item.id}>
                <tr className="table-border">
                  <td colSpan={1} className="p-3 table_rawuri">
                    {!item.duplicateRawUri && (
                      <button
                        onClick={handleRawURIExpansion(item.rawUri, uriIndex)}
                        type="button"
                        className="inline-flex rounded-md font-semibold shadow-sm transition-colors duration-150 ease-in bg-blue-600 text-white hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 px-2.5 py-1 table_rawuributton "
                      >
                        {item.isExpanded ? (
                          <i className="fa-solid fa-minus"></i>
                        ) : (
                          <i className="fa-solid fa-plus"></i>
                        )}
                      </button>
                    )}
                    <p className="table_rawurip">{item.rawUri}</p>
                  </td>
                  {item.isExpanded && (
                    <React.Fragment>
                      {[item.subRows[0]].map((flow: any) => {
                        return (
                          <React.Fragment>
                            <td
                              colSpan={1}
                              className="whitespace-nowrap text-sm py-3.5 px-3 "
                            >
                              <div className="flex">
                                {!item.duplicateFlowName && (
                                  <React.Fragment>
                                    <button
                                      onClick={handleFlowExpansion(
                                        flow.flowName,
                                        item.rawUri || item.duplicateRawUri,
                                        uriIndex
                                      )}
                                      type="button"
                                      className="inline-flex rounded-md font-semibold shadow-sm transition-colors duration-150 ease-in bg-blue-600 text-white hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 px-2.5 py-1 table_rawuributton "
                                    >
                                      {flow.isExpanded ? (
                                        <i className="fa-solid fa-minus"></i>
                                      ) : (
                                        <i className="fa-solid fa-plus"></i>
                                      )}
                                    </button>
                                    <p className="table_rawurip">
                                      {flow.flowName}
                                    </p>
                                  </React.Fragment>
                                )}
                              </div>
                            </td>
                            {flow.isExpanded && (
                              <React.Fragment>
                                {[flow.subRows[0]].map((node: any) => {
                                  return (
                                    <React.Fragment>
                                      <td
                                        colSpan={1}
                                        className="whitespace-nowrap text-sm py-3.5 px-3 "
                                      >
                                        <div className="flex">
                                          {!item.duplicateNodeId && (
                                            <React.Fragment>
                                              <button
                                                onClick={handleNodeExpansion(
                                                  node.nodeId,
                                                  flow.flowName ||
                                                    item.duplicateFlowName,
                                                  item.rawUri ||
                                                    item.duplicateRawUri,
                                                  uriIndex
                                                )}
                                                type="button"
                                                className="inline-flex rounded-md font-semibold shadow-sm transition-colors duration-150 ease-in bg-blue-600 text-white hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 px-2.5 py-1 table_rawuributton "
                                              >
                                                {node.isExpanded ? (
                                                  <i className="fa-solid fa-minus"></i>
                                                ) : (
                                                  <i className="fa-solid fa-plus"></i>
                                                )}
                                              </button>
                                              <p
                                                className="table_rawurip"
                                                onClick={(e) =>
                                                  rowClickHandler &&
                                                  rowClickHandler({
                                                    original: {
                                                      nodeId: node.nodeId,
                                                      valueKey: '',
                                                      flowName: flow.flowName,
                                                    },
                                                  })
                                                }
                                              >
                                                {node.nodeId}
                                              </p>
                                            </React.Fragment>
                                          )}
                                        </div>
                                      </td>
                                      {node.isExpanded && (
                                        <React.Fragment>
                                          {[node.subRows[0]].map((row) => {
                                            return (
                                              <React.Fragment>
                                                <td
                                                  colSpan={1}
                                                  className="whitespace-nowrap text-sm py-3.5 px-3 "
                                                >
                                                  {row.valueKey || ''}
                                                </td>
                                                <td
                                                  colSpan={1}
                                                  className="whitespace-nowrap text-sm py-3.5 px-3 "
                                                >
                                                  {row.valueExp || ''}
                                                </td>
                                                <td
                                                  colSpan={1}
                                                  className="whitespace-nowrap text-sm py-3.5 px-3 "
                                                >
                                                  {row.valueCur || ''}
                                                </td>
                                                <td
                                                  colSpan={1}
                                                  className="whitespace-nowrap text-sm py-3.5 px-3 "
                                                >
                                                  {row.dataTypeKey || ''}
                                                </td>
                                                <td
                                                  colSpan={1}
                                                  className="whitespace-nowrap text-sm py-3.5 px-3 "
                                                >
                                                  {row.dataTypeExp || ''}
                                                </td>
                                                <td
                                                  colSpan={1}
                                                  className="whitespace-nowrap text-sm py-3.5 px-3 "
                                                >
                                                  {row.dataTypeCur || ''}
                                                </td>
                                                <td
                                                  colSpan={1}
                                                  className="whitespace-nowrap text-sm py-3.5 px-3 "
                                                >
                                                  {row.uriKey || ''}
                                                </td>
                                                <td
                                                  colSpan={1}
                                                  className="whitespace-nowrap text-sm py-3.5 px-3 "
                                                >
                                                  {row.uriExp || ''}
                                                </td>
                                                <td
                                                  colSpan={1}
                                                  className="whitespace-nowrap text-sm py-3.5 px-3 "
                                                >
                                                  {row.uriCur || ''}
                                                </td>
                                                <td
                                                  colSpan={1}
                                                  className="whitespace-nowrap text-sm py-3.5 px-3 "
                                                >
                                                  {row.missingKey || ''}
                                                </td>
                                                <td
                                                  colSpan={1}
                                                  className="whitespace-nowrap text-sm py-3.5 px-3 "
                                                >
                                                  {row.extraKey || ''}
                                                </td>
                                              </React.Fragment>
                                            );
                                          })}
                                        </React.Fragment>
                                      )}
                                    </React.Fragment>
                                  );
                                })}
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  )}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExpandableTable;
