import { Viewer, Differ } from 'json-diff-kit';
import 'json-diff-kit/dist/viewer.css';

type JsonObject = Record<string, any>;
type JsonArray = any[];

interface JsonDiffCheckerProps {
  currentSample: JsonObject | JsonArray;
  expectedSample: JsonObject | JsonArray;
}

const differ = new Differ({
  detectCircular: true,
  maxDepth: undefined,
  showModifications: true,
  arrayDiffMethod: 'lcs',
  ignoreCase: false,
  recursiveEqual: true,
});

/**
 *
 */
const JsonDiffChecker = (props: JsonDiffCheckerProps) => {
  const { currentSample, expectedSample } = props;
  const diff = differ.diff(expectedSample, currentSample);
  const viewerProps = {
    indent: 4,
    lineNumbers: true,
    highlightInlineDiff: false,
    hideUnchangedLines: true,
    syntaxHighlight: false,
    virtual: false,
  };
  return <Viewer diff={diff} {...viewerProps} />;
};

export default JsonDiffChecker;
